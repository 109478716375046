export default [
    //首页
    {
        id: 1,
        text: '概览',
        url: '/index',
    },
    //营销中心
    {
        id:2,
        text:'产品中心',
        url:'/marketingCenter',
        hide:'Product',
        children:[
            // {
            //     id: 21,
            //     text: '平台产品',
            //     icon: 'el-icon-menu',
            //     url: '/allProducts'
            // },
            // {
            //     id: 22,
            //     text: '我的收藏',
            //     icon: 'el-icon-star-on',
            //     hide:'MyCollection',
            //     url: '/myFavorite'
            // },
            {
                id: 23,
                text: '我的产品',
                icon: 'el-icon-box',
                hide:'FinishedProduct',
                url: '/myProducts'
            },
            // {
            //     id: 24,
            //     text: '平台图库',
            //     icon: 'el-icon-menu',
            //     url: '/allGalleries'
            //
            // },
            // {
            //     id:24,
            //     text:'系统模板',
            //     icon:'FinishedProduct',
            //     url:'/systemTemplate'
            // },
            {
                id: 25,
                text: '我的图库',
                hide:'MyGallery',
                icon: 'el-icon-star-on',
                url: '/myGallery'
            },
        ]
    },
    //交易中心
    {
        id:3,
        text:'交易中心',
        url:'/tradingCenter',
        hide:'Deal',
        children: [
            {
                id: 51,
                text: '订单管理',
                icon: 'icon-ziliaoxiugai iconfont',
                hide:'AllOrder',
                url: '/orderManagement'
            },
            // {
            //     id: 52,
            //     text: '导入记录',
            //     hide:'ImportRecords',
            //     icon: 'icon-daorujilu iconfont',
            //     url: '/importRecords'
            // },
            // {
            //     id: 53,
            //     text: '批量发货',
            //     hide:'BulkShipping',
            //     icon: 'iconfont icon-ziliaoxiugaibeifen',
            //     url: '/bulkShipping'
            // },
            //
            // {
            //     id: 54,
            //     text: 'FBA发货计划',
            //     hide:'BulkShipping',
            //     icon: 'iconfont icon-a-FBAdeliveryplan',
            //     url: '/shipmentFba'
            // },
            // {
            //
            //     id: 55,
            //     text: '异常订单处理',
            //     icon: 'iconfont icon-a-Abnormalorders',
            //     url: '/abnormalOrder'
            // },
            {
                id: 56,
                text: '费用管理',
                hide:'Cost',
                icon: 'iconfont icon-a-Abnormalorders',
                url: '/expenseManagement',
            },
        ],
    },
    //账户中心
    {
        id: 4,
        text: '账户中心',
        hide:'Account',
        url: '/accountAccess',
        children: [
            {
                id: 41,
                text: '账号管理',
                hide:'AccountNumber',
                url: '/accountManagement',
                icon:'icon-a-Accountmanagement iconfont',
                open: false, //二级菜单有第三级必须初始化open属性
                children: [
                    {
                        id: 412,
                        text: '个人信息',
                        hide:'MerchantAddress',
                        url: '/personalInformation'
                    },
                ]
            },
            {
                id: 42,
                text: '权限管理',
                hide:'Jurisdiction',
                url: '/authorityManagement',
                open: false, //二级菜单有第三级必须初始化open属性
                icon:'icon-a-Rightsmanagement iconfont',
                children: [
                    {
                        id: 422,
                        text: '用户管理',
                        icon: 'el-icon-user',
                        hide:'Manager',
                        url: '/UserControl'
                    },
                    {
                        id: 423,
                        text: '角色管理',
                        hide:'Role',
                        icon: 'el-icon-edit',
                        url: '/RoleManagement'
                    },
                    // {
                    //     id: 424,
                    //     text: '部门管理',
                    //     hide:'DepartmentManagement',
                    //     icon: 'el-icon-setting',
                    //     url: '/DivisionManagement'
                    // },
                ]
            },

            {
                id: 43,
                text: '应用管理',
                icon: 'el-icon-link',
                hide:'Apply',
                url: '/applicationManagement',
                open: false, //二级菜单有第三级必须初始化open属性
                children: [
                    {
                        id: 431,
                        text: '应用配置',
                        hide:'ApplyConfig',
                        url: '/applicationConfiguration',
                    },
                    // {
                    //     id:412,
                    //         text:'Shopee',
                    //         url:"/shopee"
                    // }
                ]},
            {
                id: 44,
                text: '店铺管理',
                icon: 'el-icon-position',
                url: '/commonSet',
                open: false, //二级菜单有第三级必须初始化open属性
                children: [
                    {
                        id: 441,
                        text: '店铺列表',
                        hide:'Shop',
                        url: '/managementStore',
                    },
                    // {
                    //     id:412,
                    //         text:'Shopee',
                    //         url:"/shopee"
                    // }
                ]},

        ],
    },
    // {
    //     id: 4,
    //     text: '刊登',
    //     url: '/publish',
    //     hide:'Publish',
    //     children: [
    //         {
    //             id: 41,
    //             text: '店铺管理',
    //             icon: 'el-icon-position',
    //             url: '/commonSet',
    //             open: false, //二级菜单有第三级必须初始化open属性
    //             children: [
    //                 {
    //                     id: 411,
    //                     text: '亚马逊',
    //                     hide:'Shop',
    //                     url: '/managementStore',
    //                 },
    //                 // {
    //                 //     id:412,
    //                 //         text:'Shopee',
    //                 //         url:"/shopee"
    //                 // }
    //             ]},
    //         {
    //             id: 42,
    //             text: '基础设置',
    //             icon: 'el-icon-setting',
    //             url: '/basics',
    //             open: false, //二级菜单有第三级必须初始化open属性
    //             children:[
    //                 {
    //                     id: 421,
    //                     text: '产品属性',
    //                     url: '/attributeManagement',
    //                 },
    //                 {
    //                     id: 422,
    //                     text: '定价模板',
    //                     url: '/pricingTemplate',
    //                     hide:'PricingTemplate',
    //                 },
    //                 // {
    //                 //     id: 423,
    //                 //     text: '产品标题模板',
    //                 //     url: '/productCaption',
    //                 // },
    //                 // {
    //                 //     id: 424,
    //                 //     text: '自定义违禁字',
    //                 //     url: '/violateWord',
    //                 // },
    //                 // {
    //                 //     id: 425,
    //                 //     text: '自定义关键字',
    //                 //     url: '/violateKeyword',
    //                 // },
    //                 {
    //                     id:426,
    //                     text:'定价模板管理',
    //                     url:'/PricingManagement'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 53,
    //             text: '亚马逊',
    //             icon: 'icon-yamaxunmoban iconfont',
    //             url: '/amazon',
    //             hide:'Amazon',
    //             open: false, //二级菜单有第三级必须初始化open属性
    //             children:[
    //                 // {
    //                 //     id: 231,
    //                 //     text: '条形码管理',
    //                 //     url: '/magneticallyCoded',
    //                 // },
    //                 {
    //                     id: 532,
    //                     text: '汇出记录',
    //                     hide:'ExportRecords',
    //                     url: '/remitRecord',
    //                 },
    //                 {
    //                     id: 533,
    //                     text: '产品模板',
    //                     hide:'ProductTemplate',
    //                     url: '/ProductTemplate',
    //                 },
    //                 {
    //                     id: 534,
    //                     text: '创建模板',
    //                     url: '/establishTemplate',
    //                 }
    //             ]
    //         },
    //     ],
    // },
    // {
    //     id: 6,
    //     text: '费用',
    //     hide:'Cost',
    //     url: '/expenseManagement',
    // },


    // {
    //     id:8,
    //     text:'统计',
    //     url:'/statistics',
    //         hide:'Statistics',
    //     children:[
    //         {
    //             id:81,
    //             text:'交易汇总',
    //                 hide:'TransactionSummary',
    //             url:'/transactionSummary',
    //         },
    //         {
    //             id:82,
    //             text:'交易明细',
    //                 hide:'TransactionDetails',
    //             url:'/transactionDetails',
    //
    //         }
    //     ]
    // },
]
