<template>
  <div class="nav_left" :style="{'width':showDrawerMini ? '56px' : '160px'}">
    <div class="navTitle" v-if="!!leftNavTitle && !showDrawerMiniCom">{{ leftNavTitle }}</div>

    <div class="nav_left_mains">
      <template v-for="item1 in list">
        <div v-if="!!item1.children && item1.children.length > 0" :key="item1.id">
          <div class="nav_left_mains_item" :key="item1.id" @click="handleOpenOrDown(item1)">
            <div v-if="showDrawerMiniCom">
              <el-popover
                  placement="right"
                  trigger="hover">
                <i slot="reference" style="top: 50%;transform: translateY(-50%)"
                   :class="item1.icon" class="nav_left_mains_item_icon" :style="iconLeftCom"/>
                <div>
                  <template v-for="item2 in item1.children">
                    <div @click="handleSelectItem(item1.id,item2.id)" class="nav_left_mains_item2" style="height: 30px"
                         :key="item2.id">
                      <span :style="{'color':currentTabEnd === item2.id ? '#FA4A14' : ''}">{{ item2.text }}</span>
                    </div>
                  </template>
                </div>
              </el-popover>
            </div>

            <i v-else :class="item1.icon" class="nav_left_mains_item_icon" :style="iconLeftCom"/>

            <span class="nav_left_mains_item_text" :class="{'d-none':showDrawerMiniCom}">{{ item1.text }}</span>
            <i class="nav_left_mains_item_icon_open_or_close"
               :class="{'d-none':showDrawerMiniCom,'el-icon-arrow-up':item1.open,'el-icon-arrow-down':!item1.open}"/>
          </div>
          <div v-if="!showDrawerMiniCom">
            <el-collapse-transition>
              <div v-show="item1.open">
                <template v-for="item2 in item1.children">
                  <div @click="handleSelectItem(item1.id,item2.id)" class="nav_left_mains_item2" :key="item2.id" v-if="!(item2.text === '创建模板' || item2.text==='定价模板管理')"
                       :class="{'item_active':currentTabEnd === item2.id}">
                    <span class="nav_left_mains_item_text"  :class="{'d-none':showDrawerMiniCom }" >{{ item2.text }}</span>
                    <div class="mask"></div>
                  </div>
                </template>
              </div>
            </el-collapse-transition>
          </div>
         
        </div>
         <div v-else>
          <div @click="handleSelectItem(item1.id,null)" class="nav_left_mains_item2"
               :class="{'item_active':currentTab === item1.id}">

            <el-tooltip v-if="showDrawerMiniCom" effect="dark" :content="item1.text" placement="right">
              <i :class="item1.icon" class="nav_left_mains_item_icon" :style="iconLeftCom"/>
            </el-tooltip>

            <i v-else :class="item1.icon" class="nav_left_mains_item_icon" :style="iconLeftCom"/>

            <span class="nav_left_mains_item_text"
                  :class="{'d-none':showDrawerMiniCom}">{{ item1.text }}</span>
            <div class="mask"></div>
          </div>
        </div>

        
      </template>
    </div>

  </div>

</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "LeftNav",
  props: {
    leftNavTitle: {
      type: String,
      default: ''
    },
    showDrawerMini: {
      type: Boolean,
      default: true
    },
    showNav: {
      type: Boolean,
      default: true
    },
    purl: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => ({}),
    },
  },
  data: () => ({
    //当选选中的tab（第二级）
    currentTab: 0,
    //当前选中的tab (第三级)
    currentTabEnd: 0,
  }),
  computed: {
    iconLeftCom() {
      return this.showDrawerMini ? {left: '19px'} : {left: '18px'}
    },
    showDrawerMiniCom: {
      get() {
        return this.showDrawerMini
      },
      set(val) {
        this.$emit('update:showDrawerMini', val)
      }
    },
  },
  methods: {
    ...mapMutations(['setBreadcrumbData']),
    handleOpenOrDown(item) {
      item.open = !item.open
    },
    handleSelectItem(id2, id3) {
      this.currentTab = id2
      this.currentTabEnd = id3
    },
    handleDoRouterAndMapperTab(path) {
      const currentItem2 = this.list.find(e => {
        return new RegExp(this.purl + e.url).test(path);
      })
      if (currentItem2) {
        const {url, children} = currentItem2
        if (children && children.length > 0) {
          const currentItem3 = children.find(e => {
            return new RegExp(this.purl + url + e.url).test(path)
          })
          this.currentTabEnd = currentItem3.id
        }
        if (this.currentTabEnd) {
          currentItem2.open = true
        }
        this.currentTab = currentItem2.id
      } else {
        //设置第一个为默认
        const defaultItem2 = this.list[0]
        if (defaultItem2) {
          if (defaultItem2.children && defaultItem2.children.length > 0) {
            defaultItem2.open = true
            this.currentTab = defaultItem2.id
            this.currentTabEnd = defaultItem2.children[0].id
          } else {
            this.currentTab = defaultItem2.id
          }
        }
      }

      //处理面包屑
      this.processingBreadcrumbData()

    },
    //处理面包屑数据
    processingBreadcrumbData() {
      const breadcrumbData = []
      const {currentTab, currentTabEnd} = this
      const oneItem = this.list.find(e => e.id === currentTab)
      if (currentTabEnd && oneItem.children && oneItem.children.length > 0) {
        breadcrumbData[2] = oneItem.children.find(e => e.id === currentTabEnd)
      }
      breadcrumbData[1] = oneItem
      this.setBreadcrumbData(breadcrumbData)
    },
  },
  watch: {
    currentTab(val) {
      const currentItem = this.list.find(e => e.id === val)
      if (!this.currentTabEnd) {
        if (currentItem) {
          const {url} = currentItem
          if (this.$route.path !== this.purl + url) {
            this.$router.push({path: this.purl + url})
          }
        }
      }
    },
    currentTabEnd(val) {
      const currentItem1 = this.list.find(e => e.id === this.currentTab)
      if (currentItem1 && currentItem1.children && currentItem1.children.length > 0) {
        const currentItem2 = currentItem1.children.find(e => e.id === val)
        if (currentItem2) {
          const {url: url1} = currentItem1
          const {url: url2} = currentItem2
          if (this.$route.path !== this.purl + url1 + url2) {
            this.$router.push({path: this.purl + url1 + url2})
          }
        }
      }
    },
    $route({path}) {
      this.currentTab = null
      this.currentTabEnd = null
      this.handleDoRouterAndMapperTab(path)
    }
  },
  mounted() {
    setTimeout(() => {
      this.currentTab = null
      this.currentTabEnd = null
      this.handleDoRouterAndMapperTab(this.$route.path)
    }, 0)
  }
}
</script>

<style scoped lang="stylus">
.drawer
  position relative
  top 0
  left 0

.navTitle
  display flex
  align-items center
  justify-content center
  height 57px
  font-size 18px

.item
  display flex
  justify-content center

.v-tabs-bar
  .v-tab
    justify-content left

.icon
  margin-right 15px !important
  margin-left 3px

.nav_left_mains
  padding 0 2px
//
.nav_left {
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  width 160px
  height 100%
  background-color white
  box-sizing border-box
  border-right 1px solid #C2C2C2

  &_mains {
    &_item, &_item2 {
      cursor pointer
      width 100%
      padding 0 14px
      height 40px
      display flex
      justify-content flex-start
      padding-left 30px
      align-items center
      position relative

      &_icon {
        font-size 18px
        padding-top 2px
        color #333
        position absolute
        left 12px
      }

      &_text {
        padding 0 15px
        font-size 16px
        font-weight 500
        color #333333
      }

      &_icon_open_or_close {
        font-size 5px
        color #333
        padding-left 14px
        padding-top 2px
        position absolute
        right 15px
      }

      .mask {
        display none
        width 100%
        height 40px
        position absolute
        background rgba(250, 74, 20, 0.1)
        left 0
        
      }

    }

    .item_active {
      i {
        color #FA4A14
      }

      span {
        color #FA4A14
      }

      .mask {
        display block
      }
    }

  }
}
.nav_left_mains_item_text
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 20px;


.d-none {
  display none
}
</style>
