import axios from "axios";
import Cookies from 'js-cookie'

const instance = axios.create({
    baseURL: '/proxy/api',
    timeout: 30000,
    //这里初始化只会执行一次 不要在这里设置动态请求头
    headers: {},
})

instance.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

const request = (url, options, callback, fileUpload = false) => {
    //处理loading 将loading作为回调的参数传递出去
    if (!!callback && typeof callback === 'function') {
        callback(true)
    }

    //设置请求头-token
    // console.log(process.env.NODE_ENV);
    let token
    if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
        token = process.env.VUE_APP_TOKEN
    } else {
        token = Cookies.get('token')
    }

    options.headers = {}

    if (!!fileUpload) {
        options.headers['Content-Type'] = 'multipart/form-data'
    }


    options.headers['Authorization'] = `Bearer ${token}`




    return new Promise((resolve, reject) => {
        instance(url, options).then(res => {
            let {success, data, code , status, message} = res.data
            // data = !!data ? data : {}
            // msg = !!msg ? msg : 'success'
            // !!success ? resolve({data, msg}) : reject({data, msg})
            // console.log(res.data)
            if(code === 500) {
                reject('服务器异常')
            } else if(code === 401) {
                window.location.href = String(process.env.VUE_APP_HOME_URL) + '?login=1'
            }
            else if(status === 'error' || status === 'fail') {
                reject(message)
            } else {
                resolve({data, message})
            }

        }).catch(err => {


            if (!!err.response) {
                let {success, data, msg, code, status_code} = err.response.data
                if (code === 401) {
                    //退出登录
                    window.location.href = String(process.env.VUE_APP_HOME_URL) + '?login=1'
                }
                if(status_code === 500) {
                    reject('服务器异常')
                }
            } else {
                reject( '网络错误')
            }



        }).finally(() => {
            if (!!callback && typeof callback === 'function') {
                //因为Promise是微队列，所以把这里设置成宏队列，便可以在请求结束后执行
                setTimeout(() => {
                    callback(false)
                }, 0)
            }
        })
    })
}

export default request
