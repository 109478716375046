<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data:() => ({

  }),
  computed: {

  },
  methods:{

  }
};
</script>

<style lang="stylus">
.ivu-loading-bar
  //设置进度条位置
  position fixed
  z-index 9999
  top 0
  width 100%

.ivu-loading-bar-inner
  //使进度条变得平滑
  transition width .2s linear

.v-slide-group__prev,.v-slide-group__prev--disabled
  display none !important

.v-tabs-slider-wrapper
  width 150px !important
  color rgba(250, 74, 20, 0.1)
.el-popover
  width 85px !important
  min-width 10px !important
</style>
