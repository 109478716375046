import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //面包屑数据
        breadcrumbData: [],
        userInfo:{} , //用户信息
        variantInformation:null,
        subAccountInfo:{},  //子账号信息

    },
    mutations: {
        setBreadcrumbData(state, payload) {

            state.breadcrumbData = payload
           
        },
        // 设置用户信息
        setUserInfo(state,payload){
            state.userInfo = payload

        },
        //更改变体
        moreChangeable(state, more){
            state.variantInformation = more
        },
        subAccountInformation(state,obj){
            state.subAccountInfo = obj
        }
    },
    actions: {
    },
    modules: {
        
    },
})
