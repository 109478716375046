<template>
  <!--    // 资料修改-->

  <el-dialog
      title="修改密码"
      :visible.sync="changePasswordShow"
      :before-close="changePassword"
      @open="open"
  >
    <el-form :model="ruleForm" status-icon  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="手机号" >
        <el-input  style="width:  400px;" :disabled="true" v-model="ruleForm.phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="oldPassword">
        <el-input type="password" style="width: 400px;" placeholder="请输入原密码" v-model.trim="ruleForm.oldPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input type="password" style="width: 400px;" placeholder="请输入新密码" v-model.trim="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" style="width: 400px;" placeholder="请再次输入新密码" v-model.trim="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {mapState} from "vuex";
import {changePassword ,logout} from "@/api/login";

export default {
  props:{
    changePasswordShow:{
      type:Boolean,
      default:false
    }
  },
  name: "dataModification",
  data(){
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if(value === this.ruleForm.oldPassword) {
        callback(new Error('新旧密码不能一致'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        phone: '',
        oldPassword:''
      },
      rules: {
        oldPassword:[{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        pass: [
          { validator: validatePass, trigger: 'blur' },
          { pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '密码格式不正确',trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],

      }
    }
  },
  mounted() {

  },
  computed:{
    ...mapState(['userInfo'])
  },
  methods: {
    // 打开弹窗的回调
    open(){
      console.log(this.$encryptionByPhone(this.userInfo.phone))
     this.ruleForm.phone = this.userInfo.phone
    },
    // 提交
    submitForm(formName) {
      console.log(this.$encryption(this.ruleForm.oldPassword))
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const {msg} = await changePassword({
              phone:this.$encryptionByPhone(this.ruleForm.phone),
              oldPassword:this.$encryption(this.ruleForm.oldPassword),
              newPassword:this.$encryption(this.ruleForm.checkPass)
            })

            this.$message.success('修改成功，请重新登录')
            // window.location.href = String(process.env.VUE_APP_HOME_URL)
            try {
              const data = await logout()
              // this.$message.success('退出成功')
            } catch (error) {
              console.log(error);
            }
            window.location.href = String(process.env.VUE_APP_HOME_URL) + '?logout=1'
          }catch (e) {
            const {msg} = e
            this.$message.error(msg)
          }
        } else {

          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //关闭弹窗
    changePassword(){
      this.$refs.ruleForm.resetFields();
      this.$emit('changePassword',false)
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
/deep/.el-dialog{
  width:600px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
/deep/.el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #3A3A3A;
  line-height: 25px;
}
</style>