import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import encryption from "./plugins/encryption";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


import ElementUI from 'element-ui'
import '../element-variables.scss'
import './assets/styles/icon.less'

function getSearchString(key, Url) {
    let str = Url;
    str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    let arr = str.split("&");
    let obj = {};
    // 将每一个数组元素以=分隔并赋给obj对象
    for (let i = 0; i < arr.length; i++) {
        let tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    return obj[key];
}

import Cookies from 'js-cookie'
import {userOperateLog} from "./api/login";

const token = getSearchString('token', window.location.search)

if (token) {
    Cookies.set('token', token)
    window.location.replace(window.location.origin + window.location.pathname)
}

    //操作日志
    //     userOperateLog().then(e=>{
    //         // console.log(e)
    //     }).catch(e=>{
    //         // console.log(e)
    //     })

Vue.use(ElementUI)
Vue.use(VueViewer)
Vue.config.productionTip = false
new Vue({
    router,
    store,
    vuetify,
    encryption,
    render: h => h(App)
}).$mount('#app')

/*




 */
