<template>
  <div class="header">
    <h1 class="logoImg">
      <el-image :src="url" @click='location'/>
    </h1>
    <div class="flex" style="height: 100%">
      <div class="tab">
        <div class="tab-title"
             :class="tabItemClassCom(index)"
             v-for="(item, index) in list"
             :key="item.id"
             @click="change(index)"
        >
          <span>{{ item.text }}</span>
          <span class="under_active_line"></span>
        </div>

<!--        <div class="more">-->
<!--          <el-dropdown @command="changeCommand" trigger="click">-->
<!--            <span class="el-dropdown-link">更多<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></span>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item :command="list.length - listByMore.length + index"-->
<!--                                :class="{'activeCommand': list.length - listByMore.length + index === currentTab}"-->
<!--                                v-for="(item,index) in listByMore" :key="item.id">{{ item.text }}-->
<!--              </el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
<!--        </div>-->
      </div>

      <div class="userBox">
        <div class="userInfo" @click="ShoppingCart"><i class="icon-gouwuche iconfont"></i><span
            style="margin-left: 5px;cursor: pointer">购物车</span></div>
        <div class="userInfo">
          <i class="icon-shanghuhao iconfont"></i>
          <span style="margin-left: 5px">{{ merchantName }}</span>
        </div>
        <div class="userInfo">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <i class="icon-gerenzhongxin iconfont"></i><span style="margin-left: 5px">{{ userInfo.phone }}</span><i
                class="el-icon-arrow-down el-icon--right"></i>
              <!--              userInfo.merchant.length> 0  ?  userInfo.merchant[0].name : -->
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command='CostManagement' v-if="setExpense">
                <i style="margin-right: 5px" class="icon-feiyongguanli iconfont"></i>费用管理
              </el-dropdown-item>
              <el-dropdown-item command='SubAccount' v-if="setPersonalInformation">
                <i style="margin-right: 5px" class="icon-zizhanghaoguanli iconfont"></i>个人信息
              </el-dropdown-item>
              <el-dropdown-item command='dataModification' v-if="setPersonalInformation"><i style="margin-right: 5px"
                                                                                            class="icon-ziliaoxiugai iconfont"></i>修改密码
              </el-dropdown-item>
              <el-dropdown-item command='signOut'><i style="margin-right: 5px" class="icon-tuichu iconfont"></i>退出
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!--    资料修改-->
    <data-modification
        :changePasswordShow="changePasswordShow"
        @changePassword="changePasswordShow=$event"
    />
  </div>
</template>

<script>
import {getNavList} from '@/utils/nav'
import Logo from '@/assets/99diy.svg'
import {logout, getUserInfo} from '@/api/login.js';
import {mapState, mapMutations} from 'vuex';
import {throttle} from 'throttle-debounce'
import DataModification from '@/components/main/dataModification';
import Cookies from "js-cookie";

export default {
  name: "Header",
  components: {DataModification},
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    url: Logo,
    activer: 0,
    changePasswordShow: false, // 修改资料弹窗
    listByMore: [],

  }),
  mounted() {
    this.bodyClientWidthChange = throttle(300, this.bodyClientWidthChange)
    window.addEventListener('resize', e => {
      this.bodyClientWidthChange(document.body.clientWidth)
    })

  },
  computed: {
    ...mapState(['userInfo']),
    tabItemClassCom() {
      return (index) => {
        return {
          'activer': this.currentTab === index,
          ['tab_item_' + index]: true,
          'tab_item': true,
        }
      }
    },
    merchantName() {
      if (!!this.userInfo.merchant && Array.isArray(this.userInfo.merchant) && this.userInfo.merchant.length > 0) {
        return this.userInfo.merchant[0].name

      } else {
        return '数据异常'
      }
    },
    //根据权限设置费用显示问题
    setExpense() {
      // includes
      if (!!this.userInfo.permissions && Array.isArray(this.userInfo.permissions) && this.userInfo.permissions.length > 0) {
        return this.userInfo.permissions.includes('Cost')
      }
    },
    //AccountNumber
    setPersonalInformation() {
      if (!!this.userInfo.permissions && Array.isArray(this.userInfo.permissions) && this.userInfo.permissions.length > 0) {
        return this.userInfo.permissions.includes('AccountNumber')
      }
    },


    currentTab: {
      get() {
        const {path} = this.$route
        const index = this.list.findIndex(e => {
          return new RegExp(e.url).test(path);
        })
        this.render2Level(index)
        return index
      },
      set(val) {
        const currentItem = this.list[val]
        let {url, children} = currentItem
        if (children && children instanceof Array && children.length > 0) {
          const firstChildrenUrl = children[0].url
          url += firstChildrenUrl
        }
        this.render2Level(val)
        this.$router.push({path: url})
      }
    }
  },
  methods: {
    changeCommand(index) {
      this.change(index)
    },
    //首页 刊登 费用管理 设计 图库 订单 子账户 统计
    bodyClientWidthChange(width) {
      if (width >= 1200 && width < 1300) {
        this.listByMore = this.list.filter((e, i) => i > 5)
      } else if (width >= 1000 && width < 1199) {
        this.listByMore = this.list.filter((e, i) => i > 3)
      } else if (width >= 910 && width < 999) {
        this.listByMore = this.list.filter((e, i) => i > 2)
      } else if (width >= 750 && width < 909) {
        this.listByMore = this.list.filter((e, i) => i > 1)
      } else if (width < 749) {
        this.listByMore = this.list.filter((e, i) => i > 0)
      } else if (width >= 1300) {
        // width >= 1300
        this.listByMore = []
      }
    },
    handleClickLogo() {
      this.$router.push({path: '/'})
    },
    render2Level(index) {
      const navList = getNavList(index, this.list)
      const purl = this.list[index].url
      const text = this.list[index].text
      this.$emit('changeNavList', navList, purl, text)
    },
    change(index) {

      this.activer = index
      this.$router.push(this.list[index].url)
    },

    location() {
      let token
      if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
        token = process.env.VUE_APP_TOKEN
      } else {
        token = Cookies.get('token')
      }
      window.location.href = String(process.env.VUE_APP_HOME_URL + '?token=' + token)


    },
    // user
    async handleCommand(command) {
      if (command === 'CostManagement') {
        await this.$router.push({path: '/tradingCenter/expenseManagement'})
      } else if (command === 'SubAccount') {
        // 子账号
        await this.$router.push({name: 'accountAccess'})
      } else if (command === 'dataModification') {
        // 修改资料
        this.changePasswordShow = true
      } else {
        // 退出

        try {
          const data = await logout()
          // this.$message.success('退出成功')
        } catch (error) {
          console.log(error);
        }
        // window.location.href = "https://web.jvcustom.hnrjyc.com/?login=1"
        window.location.href = String(process.env.VUE_APP_HOME_URL) + '?logout=1'
      }
    },
    // 购物车
    async ShoppingCart() {

      let token
      if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
        token = process.env.VUE_APP_TOKEN
      } else {
        token = Cookies.get('token')
      }

      window.location.href = String(process.env.VUE_APP_HOME_URL) + 'shopping-cart?token=' + token;
    },

  },
  watch: {
    list: {
      handler() {
        this.bodyClientWidthChange(document.body.clientWidth)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="stylus">
$primaryColor = #fa4a14;
@import "more.styl"

.flex
  user-select none !important

  width 100%;
  display flex;
  justify-content space-between;

  .userBox
    display flex;

.userInfo
  margin 0 25px
  display flex
  align-items center
  justify-content center
  //cursor pointer;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;

  .el-dropdown
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    /deep/ .el-dropdown-link
      display flex
      align-items center

  .iconfont
    font-weight 600;

.header
  width: 100%;
  height: 60px;
  min-width: 800px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 43px;

  .logoImg
    width: calc(148px * 0.8);
    height: calc(40px * 0.8);
    margin-right: 30px;
    cursor pointer

    img
      width: 100%;
      height: 100%;

  .tab
    display: flex;

    .tab-title
      display flex
      align-items center
      justify-content center
      margin: 0 25px;
      font-size: 18px;
      color: #333;
      cursor: pointer;
      position: relative;

      .under_active_line
        display: none;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $primaryColor;
        left: 50%;
        transform: translateX(-50%);
        bottom: 15px;

    .activer
      color: $primaryColor;
      font-weight: 500;
      font-size 18px


      .under_active_line
        display: block;
        position absolute
        bottom 0
        height: 2px;
        background: $primaryColor;
        border-radius: 20px;


</style>
