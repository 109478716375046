/**
 * 点击了一级菜单，来操作二级菜单
 * @param index 被点击的一级菜单index
 * @param list 菜单列表
 */
export const getNavList = (index, list) => {
    //找到当前被点击的菜单
    const clickItem = list[index]
    //判断当前的菜单有没有子集
    const {children} = clickItem
    return children && children instanceof Array && children.length > 0 ? children : []
}
