<template>
  <div>
    <Header @changeNavList="handleHeaderChangeNavList" :list="headerData"/>

    <div class="main_div">

      <div class="nav_list">
        <LeftNav :leftNavTitle="leftNavTitle" :showDrawerMini.sync="showDrawerMini" v-if="leftNavData.length > 0"
                 ref="leftNav"
                 class="leftNav" :purl="leftNavPUrl" :list="leftNavData"/>
      </div>

      <div class="main_content">
        <div class="main-content_right">
          <div class="top" v-if="leftNavData.length > 0">


            <i :class="classCom" @click="handleToggleDrawerMini" class="handleToggle"></i>

            <ul class="breadcrumbs">
              <li v-for="(item,index) in breadcrumbData" :key="index">
                <a class="cpu" @click="url(item.url,item)" v-if="!!item"
                   :class="{'a0':index !== breadcrumbData.length - 1}">{{ item.text }}</a>
                <span class="www0" v-if="index !== breadcrumbData.length - 1">/</span>
              </li>
            </ul>
          </div>

          <router-view></router-view>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Header from "@/components/main/Header";
import LeftNav from "@/components/main/LeftNav";
import navData from "@/config/nav";
import {mapMutations, mapState} from 'vuex'
import {getUserInfo} from '@/api/login.js';

export default {
  name: "MainLayout",
  data: () => ({

    //是否缩小导航栏
    showDrawerMini: false,

    navList: [],

    //headerData
    headerData: [],

    //leftNavData
    leftNavData: [],
    leftNavPUrl: '',
    leftNavTitle: '',
  }),
  computed: {

    ...mapState({
      breadcrumbData: state => state.breadcrumbData,
      userInfo: state => state.userInfo
    }),
    classCom() {
      return this.showDrawerMini ? 'el-icon-s-unfold' : 'el-icon-s-fold'

    },
  },
  components: {
    Header,
    LeftNav,
  },
  async created() {
    await this.getUserInfo()
    await this.setHeaderData()
    // this.headerData = navData
  },
  mounted() {


  },
  methods: {
    //页面权限
    setHeaderData() {
      this.headerData = navData.filter(e => {
        if (this.userInfo.permissions.includes(e.hide) || !e.hide) {
          //level 2
          if (!!e.children && Array.isArray(e.children) && e.children.length > 0) {
            e.children = e.children.filter(ea => {
              if (this.userInfo.permissions.includes(ea.hide) || !ea.hide) {
                //level 3
                if (!!ea.children && Array.isArray(ea.children) && ea.children.length > 0) {
                  ea.children = ea.children.filter(eb => this.userInfo.permissions.includes(eb.hide) || !eb.hide)
                  if (ea.children.length <= 0) {
                    return false
                  }
                }
                return ea
              }
            })
            if (e.children.length <= 0) {
              return false
            }
          }
          return e
        }
      })


      /*let arr= []
      this.userInfo.permissions.forEach(item=>{
      navData.forEach(val=>{
          if(val.hide === item) {
            arr.push(val)
          }
        })
      })
      this.headerData = arr*/
    },
    ...mapMutations(['setBreadcrumbData', 'setUserInfo']),
    handleToggleDrawerMini() {
      this.showDrawerMini = !this.showDrawerMini
    },
    handleHeaderChangeNavList(navList, purl, text) {

      this.leftNavData = navList
      this.leftNavPUrl = purl
      this.leftNavTitle = text
      const breadcrumbData = [...this.breadcrumbData]
      breadcrumbData[0] = {text}
      this.setBreadcrumbData(breadcrumbData)
    },
    url(url, item) {
      url = url.replace(/\//g, '');
      if (!item.open) {
        this.$router.push({path: url})
      }
    },
    async getUserInfo() {
      try {
        const { data } = await getUserInfo()
        this.setUserInfo(data)
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
}
</script>

<style scoped lang="stylus">
@import "main.styl"
>>> .el-card__header
  border-bottom none

>>> .el-card__body
  padding 0 20px

.el-icon-question
  margin-left 10px;

.text
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  padding-top 15px

.border
  border-top 1px solid #E9E9E9

.cpu
  cursor pointer

</style>
