import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};
import MainLayout from "@/components/main/MainLayout";

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/index/Index')
            },
        ],
    },
    // 营销中心
    {
        name:'marketingCenter',
        path:'/marketingCenter',
        component: MainLayout,
        redirect:'/marketingCenter/myProducts',
        children: [
            // {
            //     // 全部产品
            //     path: 'allProducts',
            //     name: 'allProducts',
            //     component: () => import('@/views/design/allProducts/Index.vue'),
            // },
            // {
            //     //我的收藏
            //     path: 'myFavorite',
            //     name: 'myFavorite',
            //     component: () => import('@/views/design/myFavorite/index.vue'),
            // },
            {
                //我的产品
                path: 'myProducts',
                name: 'myProducts',
                component: () => import('@/views/design/myProducts/Index.vue'),
            },
            // {
            //     //类别管理
            //     path: 'mySortTo',
            //     name: 'mySortTo',
            //     component: () => import('@/views/design/mySortTo/index.vue'),
            // },
            // 所有图库
            // {
            //     path: 'allGalleries',
            //     name: 'allGalleries',
            //     component: () => import('@/views/mapDepot/allGalleries/index.vue'),
            // },
            {
                path: 'systemTemplate',
                name: 'systemTemplate',
                component: () => import('@/views/design/systemTemplate/index.vue'),
            },
            // 我的图库
            {
                path: 'myGallery',
                name: 'myGallery',
                component: () => import('@/views/mapDepot/myGallery/index.vue'),
            },
        ]

    },
    //交易中心
    {
        name:'tradingCenter',
        path:'/tradingCenter',
        component: MainLayout,
        redirect:'/tradingCenter/orderManagement',
        children: [
            // 订单管理
            {
                path: 'orderManagement',
                name: 'orderManagement',
                component: () => import('@/views/order/orderManagement/index.vue'),
            },
            // 导入记录
            {
                path: 'importRecords',
                name: 'importRecords',
                component: () => import('@/views/order/importRecords/index.vue'),
            },
            // 批量发货
            {
                path: 'bulkShipping',
                name: 'bulkShipping',
                component: () => import('@/views/order/bulkShipping/index.vue'),
            },
            // fba订单
            {
                path: 'shipmentFba',
                name: 'shipmentFba',
                component: () => import('@/views/order/shipmentFba/index.vue'),
            },
            // 异常订单
            {
                path: 'abnormalOrder',
                name: 'abnormalOrder',
                component: () => import('@/views/order/abnormalOrder/index.vue'),
            },
            //费用管理
            {
                path: 'expenseManagement',
                name: 'expenseManagement',
                component: () => import('@/views/expenseManagement/balanceDetails/Index')
            },
        ],
    },
    // 账户中心
    {
        // 子账号
        path: '/accountAccess',
        name: 'accountAccess',
        redirect:'/accountAccess/accountManagement/personalInformation',
        component: MainLayout,
        children: [
            //个人信息
            {
                path: 'accountManagement/personalInformation',
                name: 'personalInformation',
                component: () => import('@/views/accountAccess/accountManagement/personalInformation/index.vue'),
            },
            {
                // 用户管理
                path: 'authorityManagement/UserControl',
                name: 'UserControl',
                component: () => import('@/views/accountAccess/authorityManagement/UserControl/index.vue'),
            },
            {
                // 角色管理
                path: 'authorityManagement/RoleManagement',
                name: 'RoleManagement',
                component: () => import('@/views/accountAccess/authorityManagement/RoleManagement/index.vue'),
            },
            {
                // 部门管理
                path: 'authorityManagement/DivisionManagement',
                name: 'DivisionManagement',
                component: () => import('@/views/accountAccess/authorityManagement/DivisionManagement/index.vue'),
            },
            {
                //店铺
                path: 'commonSet/managementStore',
                name: 'managementStore',
                component: () => import('@/views/publish/commonSet/managementStore/Index'),
            },
            {
                //应用配置
                path:'applicationManagement/applicationConfiguration',
                name:'applicationConfiguration',
                component: () => import('@/views/accountAccess/applicationManagement/applicationConfiguration/index.vue'),
            }
        ],

    },


    {
        path: '*',
        name: 404,
        component: () => import('@/views/errorPage/notFind')
    },
    //#region
    //费用管理
    // {
    //     path: '/expenseManagement',
    //     name: 'expenseManagement',
    //     component: MainLayout,
    //     children: [
    //         {
    //             path: '/',
    //             name: 'balanceDetails',
    //             component: () => import('@/views/expenseManagement/balanceDetails/Index')
    //         },
    //
    //     ]
    // },
    //店铺授权成功
    // {
    //     path: '/authorization',
    //     name: 'authorization',
    //     component: ()=>import('@/views/authorization/index'),
    // },
    //店铺授权失败
    // {
    //     path: '/authorizationFail',
    //     name: 'authorizationFail',
    //     component: ()=>import('@/views/authorizationFail/index'),
    // },
    // {
    //     // 统计
    //     path: '/statistics',
    //     name: 'statistics',
    //     redirect:'/statistics/transactionSummary',
    //     component: MainLayout,
    //     children:[
    //         // 交易总汇
    //         {
    //             path:'transactionSummary',
    //             name:'transactionSummary',
    //             component:()=>import('@/views/statistics/transactionSummary/index.vue'),
    //         },
    //         // 交易明细
    //         {
    //             path:'transactionDetails',
    //             name:'transactionDetails',
    //             component:()=>import('@/views/statistics/transactionDetails/index.vue')
    //         },
    //         // 导出记录
    //         {
    //             path:'examineExport',
    //             name:'examineExport',
    //             component:()=>import('@/views/statistics/examineExport/index.vue')
    //         }
    //     ]
    // },
    // 刊登
    // {
    //     path: '/publish',
    //     name: 'publish',
    //     redirect:'/publish/commonSet/managementStore',
    //     component: MainLayout,
    //     children: [
    //         //店铺管理
    //         {
    //             path: 'commonSet/managementStore',
    //             name: 'managementStore',
    //             component: () => import('@/views/publish/commonSet/managementStore/Index'),
    //         },
    //         {
    //             path: 'commonSet/shopee',
    //             name: 'shopee',
    //             component: () => import('@/views/publish/commonSet/shopee/index'),
    //         },
    //         // SKU规则
    //         // {
    //         //     path: 'commonSet/SKUrule',
    //         //     name: 'SKUrule',
    //         //     component: () => import('@/views/publish/commonSet/SKUrule/Index')
    //         // },
    //         // 成本计算器
    //         {
    //             path: 'commonSet/costCalculator',
    //             name: 'costCalculator',
    //             component: () => import('@/views/publish/commonSet/costCalculator/index')
    //         },
    //         // 属性管理
    //         {
    //             path: 'basics/attributeManagement',
    //             name: 'attributeManagement',
    //             component: () => import('@/views/publish/basicsSet/attributeManagement/index')
    //         },
    //         // 定价模板
    //         {
    //             path: 'basics/pricingTemplate',
    //             name: 'pricingTemplate',
    //             component: () => import('@/views/publish/basicsSet/pricingTemplate/index')
    //         },
    //         {
    //             path: 'basics/PricingManagement',
    //             name: 'PricingManagement',
    //             component: () => import('@/views/publish/basicsSet/PricingManagement/index')
    //         },
    //         // 产品标题模板
    //         {
    //             path: 'basics/productCaption',
    //             name: 'productCaption',
    //             component: () => import('@/views/publish/basicsSet/productCaption/index')
    //         },
    //         // 自定义违禁字
    //         {
    //             path: 'basics/violateWord',
    //             name: 'violateWord',
    //             component: () => import('@/views/publish/basicsSet/violateWord/index')
    //         },
    //         // 自定义关键字
    //         {
    //             path: 'basics/violateKeyword',
    //             name: 'violateKeyword',
    //             component: () => import('@/views/publish/basicsSet/violateKeyword/index')
    //         },
    //         // 条形码管理
    //         {
    //             path: 'amazon/magneticallyCoded',
    //             name: 'magneticallyCoded',
    //             component: () => import('@/views/publish/amazon/magneticallyCoded/index')
    //         },
    //         // 汇出记录
    //         {
    //             path: 'amazon/remitRecord',
    //             name: 'remitRecord',
    //             component: () => import('@/views/publish/amazon/remitRecord/index')
    //         },
    //         // 产品模板
    //         {
    //             path: 'amazon/ProductTemplate',
    //             name: 'ProductTemplate',
    //             component: () => import('@/views/publish/amazon/ProductTemplate/index')
    //         },
    //         // 创建模板
    //         {
    //             path: 'amazon/establishTemplate',
    //             name: 'establishTemplate',
    //             component: () => import('@/views/publish/amazon/establishTemplate/index')
    //         }
    //     ],
    // },
    // {
    //     // 订单
    //     path: '/order',
    //     name: 'order',
    //     redirect:'/order/orderManagement',
    //     component: MainLayout,
    //     children: [
    //         // 订单管理
    //         {
    //             path: 'orderManagement',
    //             name: 'orderManagement',
    //             component: () => import('@/views/order/orderManagement/index.vue'),
    //         },
    //         // 导入记录
    //         {
    //             path: 'importRecords',
    //             name: 'importRecords',
    //             component: () => import('@/views/order/importRecords/index.vue'),
    //         },
    //         // 批量发货
    //         {
    //             path: 'bulkShipping',
    //             name: 'bulkShipping',
    //             component: () => import('@/views/order/bulkShipping/index.vue'),
    //         },
    //         // fba订单
    //         {
    //             path: 'shipmentFba',
    //             name: 'shipmentFba',
    //             component: () => import('@/views/order/shipmentFba/index.vue'),
    //         },
    //         // 异常订单
    //         {
    //             path: 'abnormalOrder',
    //             name: 'abnormalOrder',
    //             component: () => import('@/views/order/abnormalOrder/index.vue'),
    //         }
    //     ],
    //
    // },
    // {
    //     path: '/product',
    //     name: 'design',
    //     redirect:'/product/allProducts',
    //     component: MainLayout,
    //     children: [
    //         {
    //             // 全部产品
    //             path: 'allProducts',
    //             name: 'allProducts',
    //             component: () => import('@/views/design/allProducts/Index.vue'),
    //         },
    //         {
    //             //我的收藏
    //             path: 'myFavorite',
    //             name: 'myFavorite',
    //             component: () => import('@/views/design/myFavorite/index.vue'),
    //         },
    //         {
    //             //我的产品
    //             path: 'myProducts',
    //             name: 'myProducts',
    //             component: () => import('@/views/design/myProducts/Index.vue'),
    //         },
    //
    //         {
    //             //类别管理
    //             path: 'mySortTo',
    //             name: 'mySortTo',
    //             component: () => import('@/views/design/mySortTo/index.vue'),
    //         },
    //     ],
    //
    //
    // },
   
    // {   // 图库
    //     path: '/mapDepot',
    //     name: 'mapDepot',
    //     redirect:'/mapDepot/allGalleries',
    //     component: MainLayout,
    //     children: [
    //         // 所有图库
    //         {
    //             path: 'allGalleries',
    //             name: 'allGalleries',
    //             component: () => import('@/views/mapDepot/allGalleries/index.vue'),
    //         },
    //         // 我的图库
    //         {
    //             path: 'myGallery',
    //             name: 'myGallery',
    //             component: () => import('@/views/mapDepot/myGallery/index.vue'),
    //         },
    //
    //     ],
    //
    // },
    //#endregion

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

import LoadingBar from '@/components/loading-bar'
// 前置导航守卫
router.beforeEach((to, from, next) => {
    LoadingBar.start();
    next();
});
//后置
router.afterEach(route => {
    LoadingBar.finish();
});

export default router
