import request from "@/utils/request";

const prefix = '/account'

//注册
export const register = (
    {
        phone,
        password
    },
    callback
) => {
    const data = {
        phone, password
    }
    return request(`${prefix}/registerUser`, {params: data, method: 'get'}, callback)
}

//登录
export const login = (
    {
        phone,
        password
    },
    callback
) => {
    const data = {
        phone,
        password
    }
    return request(`${prefix}/getAccessToken`, {data, method: 'post'}, callback)
}

//获取验证码
export const sendVerifyCode = (
    {
        phone
    },
    callback
) => {
    const data = {
        phone
    }
    return request(`${prefix}/sendVerifyCode`, {params: data, method: 'post'}, callback)
}
//获取用户信息
// export const getUserInfo = (
//     callback
// ) => {
//     return request(`${prefix}/getUserInfo`, {params: {}, method: 'get'}, callback)
// }

export const getUserInfo = (
    callback
) => {
    return request('user/getManagerInfo', {params: {}, method: 'get'}, callback)
}

//退出登录
export const logout = (callback) => {
    return request('/user/logout', {data: {}, method: 'post'}, callback)
}
// 修改密码
export const changePassword = (
    data,
    callback
) =>
{
    return request('/user/changePassword', {data, method: 'put'}, callback)
}
//主账户下所有子账户列表
// listOfAllSubAccountsUnderTheMasterAccount
export const getASubAccount = (
    params,
    callback
) =>
{
    return request(`${prefix}/merchantSubAccountList`, {params, method: 'get'}, callback)
}

//权限列表

export const userOperateLog = (callback)=>{
    return request(`/account/userOperateLog`, { method: 'post'}, callback)
}


// 应用配置
export const appConfig = (

    callback
) =>
{
    return request('/user/appConfig', { method: 'get'}, callback)
}
